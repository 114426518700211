<template>
    <v-card elevation="2" class="my-6">
        <v-app-bar color="blue darken-2" dark flat dense>
            <v-app-bar-title>Organizations ({{ list.length }})</v-app-bar-title>
            <v-spacer/>
            <v-btn icon @click="openCreateOrganizationDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
        </v-app-bar>

    <!-- <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="blue--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="blue" @click="openCreateOrganizationDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
        </v-toolbar> -->
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No organizations yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start">
                    <OrganizationListItem :attr="item" :link="!selectOne"></OrganizationListItem>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-row justify="center" class="py-5" v-show="displayCreateOrganization">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-form v-model="createOrganizationForm" ref="createOrganizationFormRef" @submit.prevent="onSubmitForm" @keyup.enter.native="onSubmitForm">
                    <div v-if="add">
                        <v-select :items="addOrganizationChoices" v-model="addOrganizationId" label="Select an organization"></v-select>
                    </div>
                    <div v-if="create">
                        <!--
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="blue"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="organization" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        -->
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newOrganizationName
                            label="Display Name"
                            :rules="newOrganizationNameRules"
                            validate-on-blur
                            color="blue"
                            required
                            hint="The name for the organization"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <!-- <v-btn elevation="4" class="blue white--text" @click="addOrganization" :disabled="!createOrganizationForm" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn> -->
                            <v-btn elevation="4" class="blue white--text" @click="onSubmitForm" :disabled="!createOrganizationForm">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-form>
            </v-card>
            </v-col>
        </v-row>
        <CreateOrganizationDialog v-model="createOrganizationDialogVisible" @cancel="createOrganizationDialogVisible = false" @created="onOrganizationCreated"/>
    </v-card>
</template>

<script>
import OrganizationListItem from '@/components/list-item/OrganizationListItem.vue';
import CreateOrganizationDialog from '@/components/service-dashboard/CreateOrganizationDialog.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        OrganizationListItem,
        CreateOrganizationDialog,
    },

    props: {
    },

    data() {
        return {
            list: [],
            submitFormTimestamp: null,
            createOrganizationDialogVisible: false,
            // create organization
            displayCreateOrganization: false,
            createOrganizationForm: null,
            newOrganizationName: null,
            newOrganizationNameRules: [
                (v) => !!v || 'Display name is required',
                (v) => !v || isValidName(compact(v)) || 'Organization name is required',
            ],
            // add organization
            addOrganizationChoices: [],
            addOrganizationId: null,
        };
    },

    computed: {
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an organization';
            }
            switch (this.list.length) {
            case 0:
                return 'No organizations';
            case 1:
                return '1 organization';
            default:
                return `${this.list.length} organizations`;
            }
        },
    },

    watch: {
        displayCreateOrganization(value) {
            if (value && (this.add || this.create)) {
                this.initAddOrganizationChoices();
            }
        },
    },

    methods: {
        async loadOrganizationList() {
            try {
                this.$store.commit('loading', { loadOrganizationList: true });
                const response = await this.$client.user(this.$store.state.session.userId).user.getOrganizationList();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadOrganizationList failed', err);
            } finally {
                this.$store.commit('loading', { loadOrganizationList: false });
            }
        },
        onClickItem(organizationId) {
            // if (this.selectOne) {
            //     this.$emit('selected', { organizationId });
            // }
            this.$router.push({ name: 'organization-dashboard', params: { organizationId } });
        },
        openCreateOrganizationDialog() {
            this.createOrganizationDialogVisible = true;
        },
        async createOrganization() {
            this.error = false;
            console.log('createOrganization');
            const request = {
                // name: this.name,
                name: this.newOrganizationName,
                // agreeToTerms: this.isAgreeToTermsChecked,
                // interactionId: this.interactionId, // will be present if organization arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the organization to an appropriate location after organization is created
            };
            const response = await this.$client.user(this.$store.state.session.userId).user.createOrganization(request);
            console.log('createOrganization response: %o', response);
            const { isCreated, id, error } = response;
            if (isCreated) {
                const organization = { id, ...request };
                this.list.push(organization); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                this.$emit('created-organization', organization);
                this.$emit('added-organization', organization);
                this.displayCreateOrganization = false;
                this.newOrganizationName = null;
            } else if (error) {
                this.error = error;
            } else {
                this.error = 'Request failed';
            }
        },
        onSubmitForm() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            if (this.$refs.createOrganizationFormRef.validate()) {
                this.createOrganization();
            }
        },
        onOrganizationCreated() {
            this.createOrganizationDialogVisible = false;
            this.loadOrganizationList();
        },
        async addOrganization() {
            const organization = { id: this.addOrganizationId, label: this.addOrganizationChoices.find((item) => item.value === this.addOrganizationId).text };
            this.list.push(organization);
            this.$emit('added-organization', organization);
            this.displayCreateOrganization = false;
            this.addOrganizationId = null;
        },
        async initAddOrganizationChoices() {
            // create a map of which organization ids are already in the list
            const organizationMap = {};
            this.list.forEach((item) => {
                organizationMap[item.id] = true;
            });
            // when we show the add/create organization dialog, load the list of available organizations to add to the policy (list of all organizations less the ones already in the policy)
            const result = await this.$client.user(this.$store.state.session.userId).user.getOrganizationList();
            console.log(`OrganizationViewList.vue fetch organizations result: ${JSON.stringify(result)}`);
            if (result && result.list) {
                // filter the results to remove organizations already in the policy, then convert the available organizations to a choice list for the v-select [ { text, value }, ... ]
                this.addOrganizationChoices = result.list.filter((item) => !organizationMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
            }
        },
    },

    mounted() {
        this.loadOrganizationList();
    },
};
</script>
